import React, { useEffect, useRef, useCallback } from 'react'
import GeneralPageLayout from '../components/Layouts/GeneralPage'
import CallToActionButton from '../components/UI/Buttons/CallToActionButton'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionHeader from '../components/UI/SectionHeader/SectionHeader'
import SimpleButton from './../components/UI/Buttons/SimpleButton/SimpleButton'
import './../styles/pageSpecific/careers.scss'

const CareersPortalPage = ({ location }) => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Careers',
    title2: 'Portal',
    breadcrumbs: ['Careers'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  //compliance btn data

  // const ButtonData = {
  //   text: 'Compliance - Machine Readable Data',
  //   url: 'https://www.uhc.com/united-for-reform/health-reform-provisions/transparency-in-coverage-rule',
  //   icon: '',
  //   location: 'external',
  //   openSeperate: true,
  // }

  // jobCode: 2186  example
  const scriptURL = useRef({
    iframe: false,
    url: 'https://jobs.silkroad.com/Precise/ProfessionalCareers?embedded=true',
  })

  const categories = {
    'advanced-engineering': 36309,
    'program-management': 36315,
    'information-technology': 36312,
    'product-support': 36313,
    'software-solutions': 36330,
    'program-support': 36307,
    security: 36334,
    training: 36332,
  }

  const params = new URLSearchParams(location.search)
  const job = params.get('jobCode')
  const services = params.get('service') || location?.state?.service || 'all'
  const selection = params.get('category') || location?.state?.category || ''

  let benefitsButton = (
    <SimpleButton
      type="gray"
      buttonData={{
        text: 'Learn About Our Benefits',
        url: '/benefits',
        state: { type: 'professional' },
      }}
    />
  )

  if (services === 'technical') {
    scriptURL.current.url =
      'https://jobs.silkroad.com/Precise/TechnicalCareers?embedded=true'
    benefitsButton = (
      <SimpleButton
        type="gray"
        buttonData={{
          text: 'Learn About Our Benefits',
          url: '/benefits',
          state: { type: 'technical' },
        }}
      />
    )
  } else if (job) {
    benefitsButton = null
    scriptURL.current.url = `https://jobs.silkroad.com/Precise/ProfessionalCareers/jobs/${job}?embedded=true`
  } else if (services === 'all') {
    benefitsButton = null
  } else {
    if (categories[selection]) {
      scriptURL.current.url = `https://jobs.silkroad.com/Precise/ProfessionalCareers?embedded=true&StartDate=&EndDate=&SearchString=&SelectedCategory=${categories[selection]}&SelectedPositionType=`
      // document.getElementById('view-positions-backup').setAttribute('onclick', `window.open("${srcLink}",'popup','width=600,height=600,scrollbars=no,resizable=no'); return false;`)
    } else {
      benefitsButton = null
      scriptURL.current.url = `https://jobs.silkroad.com/Precise/ProfessionalCareers?embedded=true`
    }
  }

  const createScript = useCallback(() => {
    const script = document.createElement('script')
    script.src =
      'https://jobs.silkroad.com/bundles/silkroadjobs?v=D0siSzFL3TPIdggmPTF1XsVGHrAU6TlyPa80ZPb5PdU1'
    script.async = true
    script.type = 'text/javascript'
    script.setAttribute('id', 'silkroad-cx-snippet')
    script.setAttribute('data-customercode', 'Precise')
    script.setAttribute('data-action', 'cxEmbedded')
    script.setAttribute('data-domain', 'https://jobs.silkroad.com')
    if (services === 'technical') {
      script.setAttribute('data-portalcode', 'TechnicalCareers')
    } else {
      script.setAttribute('data-portalcode', 'ProfessionalCareers')
    }
    document.querySelector('#jobBoard article').appendChild(script)
  }, [services])

  useEffect(() => {
    createScript()
    scriptURL.current.iframe = true
    return () => {
      if (document.querySelector('#jobBoard article')) {
        document.querySelector('#jobBoard article').removeChild('script')
      }
    }
  }, [createScript])

  useEffect(() => {
    let myIframe = document.getElementById('silkroadJobs_cx_container')

    let checkForSilkRoad = setInterval(() => {
      myIframe = document.getElementById('silkroadJobs_cx_container')

      if (myIframe && myIframe.tagName === 'IFRAME') {
        myIframe.src = scriptURL.current.url
        myIframe.onload = function () {
          myIframe.classList.add('show')
        }
        clearInterval(checkForSilkRoad)
      }
    }, 500)

    return () => {
      clearInterval(checkForSilkRoad)
      if (document.querySelector('#jobBoard article')) {
        document.querySelector('#jobBoard article').removeChild('iframe')
      }
    }
  }, [scriptURL.current.url, scriptURL.current.iframe])

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        <SectionContainer
          id="portalIntro"
          color="white"
          type="fullWidth"
          classes={[
            'inlineChildren_mobile',
            'twoThirdsChildRight',
            'alignChildrenCenter',
          ]}
        >
          <section>
            <SectionHeader color="navy" alignment="left" size="med" trim="true">
              Open <span>Positions</span>
            </SectionHeader>
            {benefitsButton}
          </section>
          <section>
            <MainTextBlock>
              <p>
                Precise Systems offers an environment that promotes work/life
                balance and strong bonds with co-workers. Our superior benefits
                packages and growth opportunities far exceed those of other
                companies. New hires are eligible for benefits the 1st day of
                the month following their employment start date.
              </p>
            </MainTextBlock>
          </section>
        </SectionContainer>
        <SectionContainer id="jobBoard" color="white" type="fullWidth">
          {/* Houses SilkRoad JS Snippet & Iframe, dynamically injected */}
          {/* <div id='silkroadJobs_cx_container' ></div> */}
        </SectionContainer>
        <div className={`benefitsDataLink redText_btn`}>
          {/* <a
            rel={'noreferrer'}
            target={'_blank'}
            href="https://individual.carefirst.com/individuals-families/mandates-policies/machine-readable-data.page"
          >
            Compliance &ndash; Machine Readable Data
          </a> */}
          {/* <CallToActionButton
            type="colored"
            buttonData={ButtonData}
          ></CallToActionButton> */}
        </div>
      </div>
    </GeneralPageLayout>
  )
}

export default CareersPortalPage
